import React from 'react';
import style from './Utils.module.css';
import SMFaisalSulaiman from '../../assets/images/SMFaisalSulaiman.jpg'
// import RhythmBhambri from '../../assets/images/RhythmBhambri.jpg'

const AboutUs = () => {
    return (
        <div className={style.Utils}>
            <div>
                <h1>About Us</h1>

                <div className={style.utilImages}>
                    <div>
                        <img src={SMFaisalSulaiman} alt='S M Failsal Sulaiman' />
                        <span>S M Faisal Sulaiman</span>
                    </div>
                    {/* <div>
                        <img src={RhythmBhambri} alt='Rhythm Bhambri' />
                        <span>Rhythm Bhambri</span>
                    </div> */}
                </div>

                <div>
                    <h2>Who we are and what we do</h2>
                    {/* <h3>Here, you can introduce your company, organization, or yourself (if it's a personal website). Briefly describe your mission, values, and what you're passionate about. Explain the purpose of your website and what visitors can gain from it.</h3> */}
                    <h3>Welcome to HindustanTime24, your trusted source for reliable and timely news coverage. Founded by a team of dedicated journalists and media professionals, we are committed to bringing you unbiased and accurate reporting from around the globe. Our mission is to inform, educate, and empower our readers by providing in-depth analysis, insightful commentary, and the full spectrum of news that affects our world today.
                        <br />
                        At HindustanTime24, we believe in the power of information to shape societies and offer perspectives that foster understanding and dialogue. We are passionate about upholding the highest standards of journalistic integrity and transparency in all our work. Whether you're looking for the latest political developments, breakthroughs in science and technology, or trends in arts and culture, our website is designed to be your go-to hub for news that matters.
                        <br />
                        Join us daily and become part of a community that values truth, diversity, and a well-informed public.</h3>
                    <br />

                    <h2>Our story</h2>
                    {/* <h3>This is where you can share your origin story. When and how did you get started? What inspired you to create this company/organization/website?  This helps connect with your audience on a personal level.</h3> */}
                    <h3>HindustanTime24 was born from a simple, yet profound idea: to create a news platform where integrity and truth lead the way. Launched in 2024, our journey began when S.M. Faisal Sulaiman, noticed a growing need for a news source that could be relied upon in an era of information overload. Frustrated by the bias and sensationalism prevalent in mainstream media, he set out to establish a space where news would be presented in its purest form—accurate, timely, and unfiltered.
                        <br />
                        Driven by the belief that informed citizens are the foundation of a robust democracy, we started small, with a passionate team that shared a vision for change and transparency. Our commitment to delivering comprehensive news coverage quickly resonated with readers, and HindustanTime24 grew from a local initiative into the global platform it is today.
                        <br />
                        Every article we publish carries the original spirit of our founding days—a spirit of dedication and a commitment to the truth. Our story is one of passion, perseverance, and the unwavering belief that the right information can make a real difference in the world.</h3>
                    <br />
                    <h2>Our team</h2>
                    {/* <h3>Introduce the people behind the scenes! You can include short bios, pictures, and blurbs about each team member's expertise and role. This builds trust and credibility with your visitors.</h3> */}
                    <h3>Our dedicated team at HindustanTime24 is the backbone of our news operation. Composed of seasoned journalists and media professionals, each member brings unique expertise and a commitment to truth. Here’s a quick introduction to some of the key people behind your daily news:
                        <br />
                        <br />
                        Editor-in-Chief
                        <br />
                        With a seasoned career in journalism, our Editor-in-Chief ensures that all content upholds the highest standards of news reporting.
                        <br />
                        <br />
                        Senior Reporter
                        <br />
                        Specializing in political and international affairs, our Senior Reporter digs deep to bring you comprehensive and insightful news stories.
                        <br />
                        <br />
                        Multimedia Coordinator
                        <br />
                        Handling all our multimedia content, from video to interactive features, our Multimedia Coordinator makes sure you experience news in dynamic formats.
                        <br />
                        <br />
                        Research Analyst
                        <br />
                        Ensuring our reporting is accurate and well-supported, our Research Analyst is crucial for maintaining our credibility and your trust.
                    </h3>
                    <br />
                    <h2>Why choose us</h2>
                    {/* <h3>Highlight what makes you unique. What sets you apart from competitors?  Focus on the benefits you offer to your audience and the value proposition of what you do.</h3> */}
                    <h3>Choose HindustanTime24 for your daily news because we prioritize truth and integrity above all. Our commitment to unbiased reporting means you receive news presented without any slant or bias. We delve deeper than the headlines, providing thorough analyses to help you understand the implications of each story. Our team ensures that you are the first to know about the latest events with our timely updates. At HindustanTime24, we value diverse perspectives, offering a balanced view that fosters informed discussions. Plus, our innovative storytelling techniques, including articles, videos, podcasts, and infographics, make complex information accessible and engaging. Trust HindustanTime24 to respect your intelligence and meet your needs for credible and comprehensive news coverage.</h3>
                </div>
            </div>

        </div>
    )
}

export default AboutUs