import React from 'react';
import style from './Utils.module.css';

const PrivacyPolicy = () => {
    return (
        <div className={style.Utils}>
            <div>
                <h1>Privacy Policy</h1>
                <p>HT24 recognizes the importance of maintaining the privacy of every user who visits our website.  We value your privacy and appreciate your trust in us. We are committed to be transparent about the data we collect about you, how it is used  and with whom it is shared. <br /><br />

                    This Privacy Policy applies when you use/visit our website, mobile or tablet application, or  any other online service (collectively, the “Services”) that links or refers to it. It does not governs or apply to information collected or used by HT24 or its group companies through any other means.<br /><br />

                    By visiting and/or using our website, you agree to this Privacy Policy. We strongly recommend you to read this privacy policy so that you understand our approach towards the use of your personal data.<br /><br />
                    <br />
                    HT24 is licensed to operate and manage the websites of HT Media Ltd. and its subsidiaries (hereinafter referred as "Company"). HT24 manages hindustantime24.com.<br /><br />

                    We reserve the right to modify this privacy statement at any time as in required.
                </p>

                <h2>INFORMATION WE COLLECT</h2>
                <p>We collect information directly from user(s) , from third-parties and automatically through our  websites/mobile applications.<br /><br />

                    Registration for the Services may require that you supply certain personal information such as name, age, sex, contact number, email address and demographic information (area, location, IP address etc) to register. To aid convenience, users can sign up on any of our sites, and we extend the same login credentials to sign in to any of the other sites. (Single Sign-on feature). You may also register or link your account through third party accounts such as Facebook, Google etc.<br /><br />

                    We  collect limited information about your computer's connection to the Internet, including your IP address, when you visit our site. Your IP address does not identify you personally. We also collect and may store information that your computer or mobile device provides to us in connection with your use of our website/apps such as your browser type, type of computer or mobile device, browser language, IP address, mobile carrier, unique device identifier, location, and requested and referring URLs. We also receive information when you view content on or otherwise interact with our website/app/services, even if you have not created an account. We use this information to deliver our web pages to you upon request, to tailor our site to the interests of our users, to measure traffic on the website. This information may also be used by our  advertisers/third party companies to personalize content, ads etc.<br /><br />
                </p>

                <h2>ACCESS TO PERSONAL INFORMATION</h2>
                <p>
                    You may access or modify your personal information by signing on to the website. We will not modify the information provided by you. However, you must update your personal information as soon as such changes are necessitated.<br /><br />

                    Upon your request, we will close your account and remove your personal information from view as soon as reasonably possible, based on your account activity and in accordance with applicable law(s). However, we will retain the required personal information from closed accounts to comply with law, Avoid/check illegal and/or potentially prohibited activities and to enforce User Agreements; Comply with any court judgment / decree / order / directive / legal & government authority /applicable law; Investigate potential violations or applicable national & international laws; Investigate deliberate damage to the website/services or its legitimate operation; Detect, prevent, or otherwise address security and/or technical issues; safeguard p the rights, property or safety of company and/or its Directors, employees and the general public at large; Respond to Claims of third parties; and take such other actions as may be permitted by law<br /><br />
                </p>

                <h2>SHOULD YOU WANT TO OPT OUT</h2>
                <p>
                    If we possess your contact information, we may want to keep you posted about our products, services and events, through email. Should you prefer not to keep up to date with company related news and latest information on services and receive such marketing materials, please click on the unsubscribe link in the communication that you recieve.<br /><br />

                    Further, a user may withdraw the consent given to the company  on the website by communicating the same to us in writing on the email provided in the contact us section of the website. However, such withholding of information or withdrawal of consent may result in company  not being able to provide services and facilities.<br /><br />

                    We will update your preferences as soon as reasonably practical. However, do note, if you opt out of the companies  emailing list, as described here, we will not be able to remove your personal information from the databases of affiliates, franchisees or business partners with which we have already shared your personal information, prior to the date of your opt-out request.<br /><br />
                </p>
            </div>

        </div>
    )
}

export default PrivacyPolicy